import { RoutesEnum, UserRoles } from './enums';
import { RolesService } from './types';

const rolesService = (): RolesService => {
  return {
    getUserAccessPermissionAreas(userRole: UserRoles) {
      switch (userRole) {
        case UserRoles.Unknown:
        default:
          return [RoutesEnum.SignIn];
        case UserRoles.Reviewer:
          return [
            RoutesEnum.BookingLogs,
            RoutesEnum.Events,
            RoutesEnum.ExternalEvents,
            RoutesEnum.Payments,
            RoutesEnum.Payouts,
            RoutesEnum.InvitationCode
          ];
        case UserRoles.Editor:
          return [
            RoutesEnum.Bookings,
            RoutesEnum.BookingLogs,
            RoutesEnum.Events,
            RoutesEnum.ExternalEvents,
            RoutesEnum.Payments,
            RoutesEnum.Payouts,
            RoutesEnum.ModerateBookingReports,
            RoutesEnum.ModerateVideoReports,
            RoutesEnum.ModerateComments,
            RoutesEnum.ModerateVideos,
            RoutesEnum.Banners,
            RoutesEnum.Categories,
            RoutesEnum.HomePage,
            RoutesEnum.InvitationCode
          ];
        case UserRoles.SuperAdmin:
        case UserRoles.LinkedOutEvents:
          return [
            RoutesEnum.EventBookings,
            RoutesEnum.ExperienceBookings,
            RoutesEnum.BookingLogs,
            RoutesEnum.Events,
            RoutesEnum.ExternalEvents,
            RoutesEnum.Payments,
            RoutesEnum.Payouts,
            RoutesEnum.ModerateBookingReports,
            RoutesEnum.ModerateVideoReports,
            RoutesEnum.ModerateComments,
            RoutesEnum.ModerateVideos,
            RoutesEnum.Statistics,
            RoutesEnum.Users,
            RoutesEnum.Banners,
            RoutesEnum.Categories,
            RoutesEnum.HomePage,
            RoutesEnum.CouponList,
            RoutesEnum.CouponReport,
            RoutesEnum.InvitationCode,
            RoutesEnum.InvitationCodes
          ];
      }
    }
  };
};

export default rolesService;
